import React, { useContext, useState } from 'react';

import * as style from 'styles/components/account/accountProfile.module.scss';
import { LangContext } from 'context/LangContext';
import { useAuth } from 'context/UserContext';
import useAccountConfig from 'data/queries/useAccountConfig';
import ProfileFormInput from 'components/account/profile/ProfileFormInput';
import ProfileConnectedInput from 'components/account/profile/ProfileConnectedInput';
import FormattedMessage, { getFormattedMessage } from 'components/utils/FormattedMessage';
import TextEditorRender from 'components/utils/TextEditorRender';

import Modal from '../../utils/Modal';

import ConfirmDeleteAccountModal from './ConfirmDeleteAccountModal';

const ProfileForm = () => {
    const { lang } = useContext(LangContext);
    const profileData = useAccountConfig();

    const { isSignedIn, user, userName, deleteUser } = useAuth();

    const notConnectedValue = getFormattedMessage('account.connectedAccountsInvalid', lang);
    const [isShowDeleteAccountModal, setIsShowDeleteAccountModal] = useState(false);

    const onDeleteAccount = async () => {
        await deleteUser();
    };

    return (
        isSignedIn && (
            <div className={style.profileForm__container}>
                <div className={style.profileForm__alerter}>
                Vi kan tyvärr inte visa ditt medlemskort eller koppla ditt medlemsskap till Mitt Hammarby just nu på grund av problem hos leverantören. Detta påverkar dock inte förköp.
                </div>
                <ProfileFormInput
                    field="custom:name"
                    fieldType="text"
                    labelKey="form.name"
                    required
                    pattern="^[A-Za-zÄÖÅäöå ]+$"
                    baseValue={userName}
                />

                <ProfileFormInput
                    field="phone_number"
                    fieldType="tel"
                    labelKey="form.phone"
                    baseValue={user.attributes.phone_number}
                    prefixValue="+46"
                />

                <div className={style.profileForm__accounts}>
                    <h3 className="title-xs bold small-margin">
                        <FormattedMessage id="account.connectedAccounts" />
                    </h3>

                    <ProfileConnectedInput
                        fieldName="email"
                        labelKey="form.mittHammarby"
                        value={user.attributes.email}
                        manageUrl="https://konto.svenskelitfotboll.se/"
                        isEmail
                    />

                    <ProfileConnectedInput
                        fieldName="custom:ticketsMenEmail"
                        labelKey="form.ticketsMen"
                        value={user.attributes['custom:ticketsMenEmail']}
                        notConnectedValue={notConnectedValue}
                        manageUrl="https://hammarbyherr.ebiljett.nu/Account/AxsSdk"
                        connectRoute="accountTickets"
                    />

                    <ProfileConnectedInput
                        fieldName="custom:ticketsWomenEmail"
                        labelKey="form.ticketsWomen"
                        value={user.attributes['custom:ticketsWomenEmail']}
                        notConnectedValue={notConnectedValue}
                        manageUrl="https://hammarbydam.ebiljett.nu/Account"
                        connectRoute="accountTickets"
                    />
                    {/*
                    <ProfileConnectedInput
                        fieldName="custom:membershipEmail"
                        labelKey="form.membership"
                        value={user.attributes['custom:membershipEmail']}
                        notConnectedValue={notConnectedValue}
                        connectRoute="accountMembership"
                    />
                    *}
                    {/* Todo Add this field when the membership page will be integrated
                    <ProfileConnectedInput
                        fieldName="custom:membershipEmail"
                        labelKey="form.membership"
                        value={user.attributes['custom:membershipEmail']}
                        notConnectedValue={notConnectedValue}
                        connectRoute="accountMembership"
                    />
                    */}
                </div>

                <div className={style.profileForm__info}>
                    <TextEditorRender
                        content={profileData.accountProfileContent[lang.translationKey]._rawContent} />
                </div>
                { /* eslint-disable-next-line */}
                <div onClick={() => setIsShowDeleteAccountModal(true)} className={style.profileForm__deleteAccountRow}>
                    <div className="cta-yellow-ter cta-external">
                        <FormattedMessage id="account.deleteAccount" />
                    </div>
                </div>
                <Modal
                    containerStyle={style.profileForm__deleteAccountModalContainer}
                    show={isShowDeleteAccountModal}
                    onClose={() => setIsShowDeleteAccountModal(false)}
                >
                    <ConfirmDeleteAccountModal
                        onClose={() => setIsShowDeleteAccountModal(false)}
                        onDeleteAccount={onDeleteAccount}
                    />
                </Modal>
            </div>
        )
    );
};

export default ProfileForm;
